import React, {useEffect} from 'react';

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import FadeUpOnScroll from '../../../components/FadeUp';
import SplitType from 'split-type';

const AboutSection1 = (props) => {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        let titleBlock = gsap.timeline({defaults: {ease: "Power4.easeInOut"}});
        titleBlock.to(".about-page .about-detail .title-block", 1.4, {
            marginTop: 0,
            ease: "Power4.easeInOut",
            delay: 2.2,
            stagger: {
                amount: 0.1
            }
        })
        const aboutMainTitle = document.querySelector(".about-page .about-detail .title-block .section-title");
        const aboutMainDescription = document.querySelector(".about-page .about-detail .title-block .section-description");

        gsap.set(aboutMainTitle, { autoAlpha: 1 });

        const splitSectionTitle = new SplitType(aboutMainTitle, {
            type: "words,lines",
            wordsClass: "words",
            linesClass: "lines",
        })

        const tlSectionTitle = gsap.timeline({});
        tlSectionTitle.from(splitSectionTitle.words,{
          duration: 1.3,
          yPercent: 150,
          ease: "Power4.easeInOut",
          rotationX: 0.5,
          rotationY: 0.5,
          z: 0.5,
          delay: 1.8,
          stagger: {
            amount: 0.1
          }
        });

        gsap.set(aboutMainDescription, { autoAlpha: 1 });
        const splitSectionDescription = new SplitType(aboutMainDescription, {
            type: "words,lines",
            wordsClass: "words",
            linesClass: "lines",
        })
        const tlSectionDescription = gsap.timeline({});
        tlSectionDescription.from(splitSectionDescription.words,{
          duration: 1.3,
          yPercent: 150,
          ease: "Power4.easeInOut",
          rotationX: 0.5,
          rotationY: 0.5,
          z: 0.5,
          delay: 2,
          stagger: {
            amount: 0.1
          }
        });

        // setTimeout(() => {
        //     let t1 = gsap.timeline({defaults: {ease: "SlowMo.easeOut"}});
        //     t1.to("#text-reveal",{y: "0%", duration:0.6, stagger:0.2});
        // }, 3000);
    },[])
    return (
        <section className="section-1">
            <div className="container">
                <div className='about-detail'>
                    <div className="row">
                        <div className="col-lg-21">
                            <div className="title-block">
                                {/* <h1 className="h1 section-title normal-spacing"><span id="text-reveal">{props.mainTitle}</span></h1>
                                <p className="section-description"><span id="text-reveal">{props.mainDescription}</span></p> */}
                                <h1 className="h1 section-title normal-spacing">{props.mainTitle}</h1>
                                <p className="section-description">{props.mainDescription}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <FadeUpOnScroll>
                    <div className="about-gallary">
                        <div className="row">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-22">
                                <div className="gallary">
                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="image-block-1 mb" >
                                                <img
                                                    src={props.image11x?.sourceUrl}
                                                    srcSet={props.image12x?.sourceUrl + " 2x, " + props.image11x?.sourceUrl + " 1x"}
                                                    width={props.image11x?.width}
                                                    alt={props.image11x?.altText}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-13" >
                                            <div className="image-block-2 mb" >
                                                <img
                                                    src={props.image21x?.sourceUrl}
                                                    srcSet={props.image22x?.sourceUrl + " 2x, " + props.image21x?.sourceUrl + " 1x"}
                                                    width={props.image21x?.width}
                                                    alt={props.image21x?.altText}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-lg-13" >
                                            <div className="image-block-3 mb" data-aos="fade-up" >
                                                <img
                                                    src={props.image31x?.sourceUrl}
                                                    srcSet={props.image32x?.sourceUrl + " 2x, " + props.image31x?.sourceUrl + " 1x"}
                                                    width={props.image31x?.width}
                                                    alt={props.image31x?.altText}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-10">
                                            <div className="image-block-4" data-aos="fade-up" >
                                                <img
                                                    src={props.image41x?.sourceUrl}
                                                    srcSet={props.image42x?.sourceUrl + " 2x, " + props.image41x?.sourceUrl + " 1x"}
                                                    width={props.image41x?.width}
                                                    alt={props.image41x?.altText}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-1"></div>
                        </div>
                    </div>
                </FadeUpOnScroll>
            </div>
        </section>
    )
}

export default AboutSection1